<template>
  <div class="row" id="base">
    <div class="col-sm-3" id="left">
      <leftChat />
    </div>
    <div class="col-sm-6" id="center">
      <centerChat
        :messages="messages"
        @sendMessage="onChildSendMessage"
      ></centerChat>
    </div>
    <div class="col-sm-3" id="right">
      <rightChat @sendCannedMessage="onChildSendMessage"></rightChat>
    </div>
  </div>
</template>

<script>
// primary
import leftChat from "@/components/chat/LeftChat.vue";
import centerChat from "@/components/chat/CenterChat.vue";
import rightChat from "@/components/chat/RightChat.vue";

// additional
import $ from "jquery";
import Push from "push.js"; // for notif desktop
import { avatar, message } from "@/core/mixins/mixin_helper.js";

export default {
  mixins: [avatar, message],
  components: {
    leftChat,
    centerChat,
    rightChat
  },
  data() {
    return {
      messages: [
        {
          name: "Naruto Uzumaki",
          time: "23 Jan 15:37",
          image: this.mixGravatar("Naruto Uzumaki"),
          fromMe: false,
          message:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        {
          name: "John Doe",
          time: "23 Jan 12:05",
          image: this.mixGravatar("John Doe"),
          fromMe: true,
          message:
            "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
          name: "Naruto Uzumaki",
          time: "23 Jan 15:37",
          image: this.mixGravatar("Naruto Uzumaki"),
          fromMe: false,
          message: "Naik naik ke puncak gunung"
        },
        {
          name: "John Doe",
          time: "23 Jan 12:05",
          image: this.mixGravatar("John Doe"),
          fromMe: true,
          message:
            "Mendaki gunung lewati lembah sungai mengalir indah ke samudra"
        }
      ] // master message
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * notif desktop web
     * --------------------------------------------------------------
     */
    createNotif: function(name, message) {
      Push.create(name, {
        body: message,
        icon: this.mixGravatar(`${name}`),
        timeout: 4000,
        onClick: function() {
          window.focus();
          this.close();
        }
      });

      // delay karna tidak bisa menyesuaikan dom
      setTimeout(() => {
        this.scrollToBottomChat();
      }, 600);
    },

    /**
     * --------------------------------------------------------------
     * trigger child : mengirim pesan
     * @param : { type:'reguler|canned', message:'' }
     * --------------------------------------------------------------
     */
    onChildSendMessage: function(msg) {
      let message = this.mixGenerateMessage.human(
        "John Doe",
        this.mixBr(msg.message),
        true
      );
      this.messages.push(message);

      setTimeout(() => {
        this.scrollToBottomChat();

        let client = "Naruto Uzumaki";
        let pesan = `client : ${msg.message}`;
        message = this.mixGenerateMessage.human(
          client,
          this.mixBr(pesan),
          false
        );
        this.messages.push(message);
        this.createNotif(client, pesan);
        setTimeout(() => {
          this.scrollToBottomChat();
        }, 800);
      }, 1000);
    },

    /**
     * --------------------------------------------------------------
     * to scroll ed of element chat, must be use timeout
     * tidak dapat manipulasi dom langsung
     * --------------------------------------------------------------
     */
    scrollToBottomChat: function() {
      $("#chat").scrollTop(parseInt($("#chat")[0].scrollHeight));
    }
  }
};
</script>

<style scoped>
#base {
  height: 90vh;
  /*overflow-y: hidden;*/
  margin-right: 0;
  margin-left: 0;
}
#left {
  padding-right: 0;
  padding-left: 0;
}
#center {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;

  padding-right: 0;
  padding-left: 0;
}
#right {
  /*border-left: 2px solid #bdc3c7;*/
  background: #f7f7f7; /*#40D9FF;*/
}
</style>
