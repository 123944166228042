<template>
  <div>
    <div class="text-center mt-5">
      <i class="fa fa-user-tie fa-3x"></i>
      <hr />
      <h5>Naruto Uzumaki</h5>
      <h6>178.12.888.11</h6>
      <h6>client@rumahweb.aja</h6>
      <h6>https://www.rumahweb.com</h6>

      <!-- button -->
      <div class="row">
        <div class="col-sm-6">
          <button
            class="btn btn-block my-2 btn-outline-info"
            @click="transfer()"
          >
            <i class="fa fa-exchange-alt"></i> Transfer Chat
          </button>
        </div>
        <div class="col-sm-6">
          <a
            href="./2021-03-16:11.55.38_addin.txt"
            download
            class="btn btn-block my-2 btn-outline-success"
          >
            <i class="fa fa-download"></i> Export Chat
          </a>
        </div>
        <div class="col-sm-6">
          <button
            class="btn btn-block my-2 btn-outline-primary"
            @click="cannedChat()"
          >
            <i class="far fa-comments"></i> Canned Msg
          </button>
        </div>
        <div class="col-sm-6">
          <button
            class="btn btn-block my-2 btn-outline-danger"
            @click="endChat()"
          >
            <i class="fa fa-power-off"></i> End Chat
          </button>
        </div>
      </div>
    </div>

    <!-- modal collection -->

    <!-- modal transfer -->
    <div class="modal fade" id="myModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="fa fa-exchange-alt"></i> Transfer Conversation
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <form @submit.stop.prevent="transferSubmit">
              <div class="form-group">
                <label for="">Departement:</label>
                <select
                  class="form-control"
                  v-model="form.transfer.departement"
                >
                  <option value="Billing">Billing</option>
                  <option value="Sales">Sales</option>
                  <option value="Technical Support">Technical Support</option>
                </select>
              </div>
              <div class="form-group">
                <label for="pwd">Staff Name (optional):</label>
                <select class="form-control" v-model="form.transfer.stafName">
                  <option value="general" selected="">General</option>
                  <option value="addin A">Addin 123</option>
                  <option value="addin B">Addin 456</option>
                </select>
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal transfer -->

    <!-- modal canned message -->
    <div class="modal fade" id="myModalCanned">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">
              <i class="fa fa-comments"></i> Canned Message
            </h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            <div id="accordion">
              <div class="card" v-for="(msg, key) in cannedMsg" :key="key">
                <div class="card-header">
                  <a
                    class="card-link"
                    :class="key !== 0 ? 'collapsed' : ''"
                    data-toggle="collapse"
                    :href="cannedChatId(key, 'href')"
                  >
                    {{ msg.title }}
                  </a>
                  <a
                    class="float-right text-danger"
                    @click="cannedChatSend(key, $event)"
                  >
                    <i class="fa fa-paper-plane"></i>
                  </a>
                </div>
                <div
                  :id="cannedChatId(key, 'id')"
                  class="collapse"
                  :class="key === 0 ? 'show' : ''"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    {{ msg.body }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal canned message -->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      form: {
        transfer: {
          departement: "Billing",
          stafName: "general"
        }
      },
      cannedMsg: [
        {
          title: "Close conversation",
          body: "Jika sudah tidak ada pertanyaan lagi, chat ini akan kami tutup"
        },
        {
          title: "Greeting",
          body: "Halo, apakah ada yang bisa kami bantu ?"
        },
        {
          title: "Hotline",
          body: "Bisa menghubungi nomor xxx-xxxx"
        }
      ]
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * transfer to internal or other departement, can personal / grup
     * --------------------------------------------------------------
     */
    transfer: function() {
      $("#myModal").modal();
    },

    /**
     * --------------------------------------------------------------
     * transfer proses
     * --------------------------------------------------------------
     */
    transferSubmit: function() {
      $("#myModal").modal("hide");
      this.$swal(
        "success",
        `Conversation has been transfer to ${this.form.transfer.departement}.`,
        "success"
      );
    },

    /**
     * --------------------------------------------------------------
     * export chat to .txt
     * --------------------------------------------------------------
     */
    exportChat: function() {
      this.$swal("export");
    },

    /**
     * --------------------------------------------------------------
     * message kaleng jika staf malas ngetik
     * --------------------------------------------------------------
     */
    cannedChat: function() {
      $("#myModalCanned").modal();
    },

    /**
     * --------------------------------------------------------------
     * get canned id untuk accordion biar bisa show hide
     * --------------------------------------------------------------
     */
    cannedChatId: function(key, tipe) {
      return tipe === "href" ? `#canned${key}` : `canned${key}`;
    },

    /**
     * --------------------------------------------------------------
     * proses klik kirim canned message
     * --------------------------------------------------------------
     */
    cannedChatSend: function(key, e) {
      e.preventDefault();
      this.$emit("sendCannedMessage", {
        type: "canned",
        message: this.cannedMsg[key].body
      });
      $("#myModalCanned").modal("hide");
    },

    /**
     * --------------------------------------------------------------
     * proses to close conversation
     * --------------------------------------------------------------
     */
    endChat: function() {
      this.$swal({
        title: "Warning",
        html: "Are you sure want to be close this conversation ?",
        icon: "warning",
        showCancelButton: true
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.$swal("Success", "Conversation closed", "success");
        }
      });
    }
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
</style>
