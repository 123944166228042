<template>
  <div>
    <!-- header -->
    <div class="card" id="header">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <a class="mt-2 text-muted"
            ><i class="far fa-comments"></i> Naruto Uzumaki</a
          >
          <form @submit.stop.prevent>
            <input
              type="text"
              class="form-control"
              @keyup.13="searching"
              placeholder="Search..."
              v-model="form.search"
            />
          </form>
        </div>
      </div>
    </div>

    <!-- chat panel -->
    <div id="chat" class="container-fluid">
      <!-- event when pencarian -->
      <div class="my-2  text-muted" v-if="isSearching">
        <i class="fa fa-search"></i> {{ form.search }}
        <span
          class="badge badge-info float-right"
          style="cursor: pointer"
          @click="closeSearch"
        >
          <i class="fa fa-times"></i> Close search
        </span>
        <hr class="mb-0 mt-0 my-2" />
      </div>

      <!-- chat masuk -->
      <div
        class="rw-chat my-1"
        :class="msg.fromMe ? 'rw-chat-right' : ''"
        v-for="(msg, key) in messageShow"
        :key="key"
      >
        <div class="rw-chat-info clearfix">
          <span
            class="direct-chat-name"
            :class="msg.fromMe ? 'float-right' : 'float-left'"
            >{{ msg.name }}</span
          >
          <span
            class="direct-chat-timestamp"
            :class="msg.fromMe ? 'float-left' : 'float-right'"
            >{{ msg.time }}</span
          >
        </div>

        <img
          class="rw-chat-img"
          :src="msg.image"
          :alt="msg.name"
          :title="msg.name"
        />

        <div class="rw-chat-text">
          <span v-html="msg.message"></span>
          <!-- <div class="float-right">
          	<small>{{ msg.time }}</small>
          </div> -->
          <div style="clear: both"></div>
        </div>
      </div>
    </div>

    <!-- panel input message gess -->
    <div id="rw-message-box">
      <div class="d-inline-flex" style="width: 100%">
        <div id="uploadFile" class="py-3">
          <span class="hiddenFileInput p-1">
            <input type="file" @change="onChooseFile" />
          </span>
        </div>
        <twemoji-textarea
          :emojiData="emojiDataAll"
          :emojiGroups="emojiGroups"
          @enterKey="onEnterKey"
          @contentChangedHtml="contentChanged"
          :enableSendBtn="false"
          :pickerWidth="500"
          class="flex-grow-1"
          placeholder="Write something..."
        >
        </twemoji-textarea>
        <div class="p-2" style="background:#F7F7F7">
          <i
            class="fa fa-paper-plane text-info fa-2x p-2"
            @click="sendMessage"
          ></i>
        </div>
      </div>
    </div>

    <!-- loading overlay -->
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#3498db"
      loader="bars"
    ></loading>
  </div>
</template>

<script>
// loading overlay
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import _ from "lodash"; // for manipulate array js
import $ from "jquery"; // for manipulate element dom
import { TwemojiTextarea } from "@kevinfaguiar/vue-twemoji-picker";
import EmojiAllData from "@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json";
import EmojiGroups from "@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json";

// set dinamis chat box
$(window).bind("resize", function() {
  let viewHeight = $(window).height();
  console.warn("resize", viewHeight);
  $("#chat").css({ height: viewHeight - 200 });
});

export default {
  props: {
    messages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isSearching: false, // true jika dalam mode cari
      isLoading: false, // loading overlay
      form: {
        search: ""
      },
      message: "", // for new send message
      messageShow: [] // for data showing to staff / manipulate filter/search
    };
  },
  components: {
    "twemoji-textarea": TwemojiTextarea,
    loading
  },
  computed: {
    emojiDataAll() {
      return EmojiAllData;
    },
    emojiGroups() {
      return EmojiGroups;
    }
  },
  methods: {
    onEnterKey() {
      this.sendMessage();
    },
    sendMessage: function() {
      this.$emit("sendMessage", { type: "reguler", message: this.message });

      if (this.message != "") {
        $("#twemoji-textarea").html(""); //reset
      } else {
        this.$swal("error", "Message is required", "error");
      }
    },

    /**
     * --------------------------------------------------------------
     * trigger ke model message karna textarea nya menggunakn 3th part
     * --------------------------------------------------------------
     */
    contentChanged(msg) {
      this.message = msg;
    },

    /**
     * --------------------------------------------------------------
     * for proses pencarian keyword in conversation / 1 thread
     * --------------------------------------------------------------
     */
    searching: function() {
      this.isLoading = !this.isLoading;
      this.isSearching = true;

      let cari = this.form.search;
      let show = _.filter(this.messages, msg => {
        return _.includes(msg.message, cari);
      });
      this.messageShow = show;

      // dummy loading
      setTimeout(() => {
        this.isLoading = !this.isLoading;
      }, 1000);
    },

    /**
     * --------------------------------------------------------------
     * untuk menampilkan all message
     * --------------------------------------------------------------
     */
    closeSearch: function() {
      this.isLoading = !this.isLoading;
      this.isSearching = false;
      this.form.search = "";
      this.messageShow = this.messages;

      // dummy loading
      setTimeout(() => {
        this.isLoading = !this.isLoading;
      }, 1000);
    },

    /**
     * --------------------------------------------------------------
     * upload image gan
     * --------------------------------------------------------------
     */
    onChooseFile: function(e) {
      let image = e.target.files[0];
      let allowedExt = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/bmp"
      ];

      if (allowedExt.includes(image.type)) {
        let size = Math.round(image.size / 1024);
        if (size > 2048) {
          this.$swal({
            title: "Error",
            html: `Ukuran gambar maksimal 2MB!`,
            icon: "error"
          });
        } else {
          let msg = URL.createObjectURL(image);
          msg = `<a href="${msg}" target="_blank"><img src="${msg}" class="img-fluid" style="width:100px"></a>`;
          this.$emit("sendMessage", { type: "reguler", message: msg });
        }
      } else {
        console.warn("not allowedExt", image);
        // alert when fail
        let ext = image.type.split("/");
        ext = ext[1].split("."); // get last ext
        this.$swal({
          title: "Error",
          html: `<b>${ext[ext.length - 1]}</b> tidak diizinkan! <br> \
	  			<small><b>PNG | JPEG | JPG | GIF | BMP </b></small>`,
          icon: "error"
        });
      }
    }
  },
  mounted() {
    this.messageShow = this.messages;
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
#chat {
  height: 410px;
  overflow: hidden auto;
  /*background: url("../../assets/images/background-1-99a36524645be823aabcd0e673cb47f8.png");*/
}
#rw-message-box {
  /*position: absolute;*/
  bottom: 0;
  width: 100%;
}
#header {
  background: #f7f7f7;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  /*border-bottom: 2px solid #bdc3c7;*/
}
#header > .card-body {
  padding: 0.8em;
}

a {
  /*color: black !important;*/
  font-weight: bold;
}
.fa-paper-plane,
.fa-camera {
  cursor: pointer;
}
.rw-chat {
  /*margin-bottom: 1rem;*/
  /*margin-top: 1rem;*/
}
.rw-chat,
.rw-chat-text {
  display: block;
}
.rw-chat:before,
.rw-chat:after {
  content: " ";
  display: table;
}
.rw-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
}
.direct-chat-name {
  font-weight: 600;
}
.rw-chat-img {
  border-radius: 50%;
  float: left;
  /*width: 40px;*/
  height: 30px;
}
img {
  vertical-align: middle;
  border: 0;
}
.rw-chat-text {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #d2d6de;
  border: 1px solid #d2d6de;
  margin: 5px 0 0 50px;
  color: #444;
}

.rw-chat,
.rw-chat-text {
  display: block;
}

.rw-chat-text:before {
  border-width: 6px;
  margin-top: -6px;
}
.rw-chat-text:after,
.rw-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
}
.rw-chat-text:after {
  border-width: 5px;
  margin-top: -5px;
}

.rw-chat-text:after,
.rw-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  /*border: solid transparent;*/
  border-right-color: #d2d6de;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
}

.rw-chat-right .rw-chat-text {
  margin-right: 50px;
  margin-left: 0;
  background: #d2d6de;
}
.rw-chat-right > .rw-chat-text {
  background: #f39c12;
  border-color: #f39c12;
  color: #fff;
}
.rw-chat-right .rw-chat-text:after,
.rw-chat-right .rw-chat-text:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #f39c12;
}

.rw-chat-right .rw-chat-img {
  float: right;
}

/**
	 * maipulate hidden button upload
	 */
#uploadFile {
  background: rgb(247, 247, 247);
}
.hiddenFileInput > input {
  /*height: 100%;*/
  width: 40px;
  opacity: 0;
}
.hiddenFileInput {
  display: inline-block;
  overflow: hidden;
  background: center center no-repeat;
  background-image: url("../../assets/svg/camera-solid.svg");
  /*margin-top: 19%;*/
}
</style>
