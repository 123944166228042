<template>
  <div>
    <!-- header -->
    <div class="card" id="header">
      <div class="card-body text-center">
        <span
          class="badge border border-secondary text-secondary"
          @click="getPeople('all')"
          >All</span
        >
        &nbsp;
        <span
          class="badge border border-danger text-danger"
          @click="getPeople('dorman')"
        >
          Client Away</span
        >
        &nbsp;
        <span
          class="badge border border-warning text-warning"
          @click="getPeople('needReply')"
          >Staff Pending</span
        >
        &nbsp;
        <span
          class="badge border border-success text-success"
          @click="getPeople('waitReply')"
          >Client Pending</span
        >
      </div>
    </div>
    <br />

    <!-- content body -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 my-1" v-for="(user, key) in peoples" :key="key">
          <div
            class="card shadow card-people"
            :class="user.status"
            @click="getMessage(user)"
          >
            <div class="card-body text-center text-truncate">
              <i
                class="fa fa-bell text-warning ring faa-ring animated new-msg"
                v-if="user.new_msg"
                title="New Message"
              ></i>
              <a href="javascript:void(0)" :title="user.nama">{{
                user.nama
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      users: [
        {
          nama: "Boa Hancook",
          status: "bg-danger",
          new_msg: false
        },
        {
          nama: "Naruto Uzumaki",
          status: "bg-active",
          new_msg: false
        },
        {
          nama: "Monkey D. Luppi",
          status: "bg-warning",
          new_msg: true
        },
        {
          nama: "Eren Yaeger",
          status: "bg-danger",
          new_msg: false
        },
        {
          nama: "Jashon Statam",
          status: "bg-success",
          new_msg: false
        },
        {
          nama: "Ir. Soekarno ke 4",
          status: "bg-warning",
          new_msg: true
        }
      ],
      peoples: []
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * for filter dialog
     * --------------------------------------------------------------
     */
    getPeople: function(tipe = "all") {
      let people;
      switch (tipe) {
        // more than 5 minute
        case "dorman":
          people = _.filter(this.users, usr => {
            return usr.status === "bg-danger";
          });
          break;

        // waiting response client
        case "waitReply":
          people = _.filter(this.users, usr => {
            return usr.status === "bg-success";
          });
          break;

        // staf not yet reply
        case "needReply":
          people = _.filter(this.users, usr => {
            return usr.status === "bg-warning";
          });
          break;

        // all
        default:
          people = this.users;
          break;
      }
      this.peoples = people;
    },

    getMessage: function(dialog) {
      console.log(dialog);
      this.$swal(
        "success",
        `Detail message ${dialog.nama} was loaded`,
        "success"
      );
    }
  },
  mounted() {
    this.peoples = this.users;
  }
};
</script>

<style scoped>
#header {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  line-height: 1.5;
  background: #f7f7f7;
}
.card-people {
  border-top: 3px solid #00a65a;
}
.card-people > .card-body {
  padding: 8%;
}
.bg-danger {
  background: transparent !important;
  border-top-color: #dc3545;
}
.bg-success {
  background: transparent !important;
  border-top-color: #28a745;
}
.bg-warning {
  background: transparent !important;
  border-top-color: #ffc107;
}
.bg-active {
  background: #e5f5f8 !important;
  /*border-top-color: #17a2b8;*/
  /*border-top: 0px;*/
}
.bg-active > .card-body > a {
  /*color: lightstalegray;*/
  font-weight: bold;
}
a,
.text-truncate {
  color: grey;
}
a:hover {
  text-decoration: none;
}
.new-msg {
  position: absolute;
  top: 6%;
  left: 3%;
}

.badge {
  cursor: pointer;
}

.bg-active {
  /*background: #40D9FF;*/
}
/**
	 * ----------------------------------
	 * for animate ringing new message
	 * ----------------------------------
	 */
@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.faa-ring.animated,
.faa-ring.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-ring {
  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
  transform-origin-x: 50%;
  transform-origin-y: 0px;
  transform-origin-z: initial;
}
</style>
